import * as React from 'react';

const InstructorList = ({ data }) => {
    return (
        <section className="section-lesson">
            {/* <h3>강사진</h3> */}
            <div className="instructor-list">
                <ul>
                    { data.map((profile, i) => 
                        <li key={i} className="card">
                            <div className="profile">
                                { profile.images.length > 0 && <img className="photo" src={profile.images} alt="프로필 사진" /> }
                                { profile.images.length === 0 && <div className="photo empty"></div> }
                                {/* { profile.images.length === 0 && <img className="profile empty" src="https://teetime.cc/img-logo.png" alt="프로필 사진 썸네일" /> } */}
                            </div>
                            <div className="col">
                                <span>{profile.name}</span>
                                { profile.note && <p>{profile.note}</p> }
                                <ol>
                                    { profile.info.map((row, i) =>
                                    <li key={i}>
                                        {row}
                                    </li>
                                    ) }
                                </ol>
                            </div>
                        </li>
                    ) }
                </ul>
            </div>
        </section>
    );
}

export default InstructorList;