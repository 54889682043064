import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import { geohashQueryBounds, distanceBetween } from 'geofire-common';
import Kakao from './Kakao.jsx';
import meta from '../static/meta.json';

export default class Backend {

  static myInstance = null;

  static getInstance = (props) => {
    if (this.myInstance == null)
      Backend.myInstance = new Backend(props);

    return this.myInstance;
  }

  constructor(props) {
    if (!firebase) {
      console.error('firebase not initialized');
      return;
    }

    this.props = props;

    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DATABASE_URL,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_ID,
        measurementId: process.env.REACT_MEASUREMENT_ID
      });
    }
  
    this.fst = firebase.firestore();
    this.auth = firebase.auth();
    this.fauth = firebase.auth;
    this.auth.languageCode = 'ko';
    this.authConfirmationResult = null;

    this.dataRef = this.fst.collection("_data");  
    this.statRef = this.fst.collection("_stat");
    this.teetimeRef = this.fst.collection("_teetime").doc("screen");
    this.usersRef = this.fst.collection("_users");
    this.reviewsRef = this.fst.collection("_reviews");
    this.logsRef = this.fst.collection("_log");
    this.analysisRef = this.fst.collection("_analysis");

    this.imageRef = firebase.storage().ref();

    this.user = {};
    this.kakao = new Kakao();
    // this.naver = new Naver();

    this.usersRefListner = null;
    this.meta = meta;

    //const referrer = document.referrer;
    //this.external = referrer.includes('xperon')? true : false;

    this.onAuthStateChanged();
  }

  /////////////////////////////////////////////////////////////////////////////////

  _setUser = (_user) => {
    this.user = _user;
    this.props.setUser(this.user);
  }

  _setData = (_data) => {
    this.props.setData(_data);
    this.props.setIsLoading(false);
  }

  /////////////////////////////////////////////////////////////////////////////////

  getCurrentPosition = () => {
    this.props.setIsLoading(true);

    if (navigator && navigator.geolocation) {
        return new Promise((resolve, reject) => {
          const coordTimeout = setTimeout(() => reject(false), 2500);

          navigator.geolocation.getCurrentPosition(
              (position) => {
                  clearTimeout(coordTimeout);
                  resolve([position.coords.latitude, position.coords.longitude]);
              },
              () => reject(false),
              {maximumAge: 10000, timeout: 2500, enableHighAccuracy: false}
          );
        });
    }
    else {
        return new Promise((_, reject) => reject(false));
    }
  }

  /////////////////////////////////////////////////////////////////////////////////

  filterData = (data, tags) => {
    data.forEach((row) => {
      row.hidden = false;

      const matched = Object.keys(tags).every((k) => {
          const tvals = tags[k];
          const val = k in row? row[k] : null;
          
          return (val && (
                  (Array.isArray(val) && tvals.every(tval => val.includes(tval))) // _tags AND
                  || (tvals.includes(val))    // _ttype OR
              ));
      });

      row.hidden = !matched;
    });

    this._setData([...data]);
  }

  /////////////////////////////////////////////////////////////////////////////////

  geoQuery = (query) => {
    this.props.setIsLoading(true);

    let ps = [];
    const bounds = geohashQueryBounds(query.center, 1000);

    for (const b of bounds) {
      const q = this.dataRef
        .orderBy('_geo.geohash')
        .startAt(b[0])
        .endAt(b[1]);
    
      ps.push(q.get());
    }

    Promise.all(ps)
      .then((snapshots) => {
        let pss = [];

        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            const _data = doc.data();
            const _coords = [_data._geo.geopoint.latitude, _data._geo.geopoint.longitude]
            const _dist = distanceBetween(_coords, query.center) * 1000;
            
            let _row = this.processRow({ _id: doc.id, ..._data, _dist: _dist });
            pss.push(_row);
          }
        }

        return Promise.all(pss).catch(() => new Error('processRow error'));
      })
      .then((data) => {
        data.sort((a, b) => a._dist - b._dist);
        this._setData(data);
      })
      .catch((e) => {
        console.error(e);
        this._setData([]);
      }); 
  }

  searchQuery = (rows) => {
    let ps = [];

    rows.forEach((row) => {
      let p = this.dataQuery(row.id);
      ps.push(p);
    });

    return Promise.all(ps)
      .then((rows) => {
        if (!rows || rows.length === 0)
          return this._setData([]);

        const data = rows.filter((row) => (row && row._ttype !== undefined));

        this._setData(data);
      })
      .catch((err) => {
        console.error(err);

        this._setData([]);
      });    
  }

  keywordSearch = (keyword, callbackFn = () => {}) => {
    this.props.setIsLoading(true);

    const _url = 'https://OJ2ZPZP81S-dsn.algolia.net/1/indexes/teetime.cc/query';

    const _opts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Algolia-Application-Id': 'OJ2ZPZP81S',
        'X-Algolia-API-Key': '40e5721d5c67bd462daa6fce5882b76a'
      },
      body: JSON.stringify({ params: `query=${keyword}&hitsPerPage=20&getRankingInfo=0` })
    };
    
    //this.props.resetKey(+ new Date());
    console.debug('keywordSearch', keyword);

    return fetch(_url, _opts)
      .then(res => res.json())
      .then((res) => {
        return res.hits;
      })
      .then((rows) => {
        if (rows.length > 0 && rows[0] && rows[0].lat != null && rows[0].lng != null)
          callbackFn([parseFloat(rows[0].lat),parseFloat(rows[0].lng)]);
        else
          this.searchQuery(rows.map((row => ({ ...row, id: row.objectID }))));
      })
      .catch((err) => {
        console.error(err);
        this._setData([]);
      });
  }

  _cleanupKeys = (_info) => {
    Object.keys(_info).forEach((k) => {
      if (k.substring(0, 1) === '_') {
        const _k = k.substring(1);
        _info[_k] = _info[k];
        delete _info[k];
      }
    });

    return _info;
  }

  processRow = (data) => {
    delete data._data;
    data._info = this._cleanupKeys(data._info);
      
    return this.statRef.doc(data._id).get()
      .then((res) => {
        const row = res.data();
        
        if (row && row.reviews && row.reviews > 0)
          data._tags.push('review');
        if (row && row.teetimes && row.teetimes > 0)
          data._tags.push('teetime');
        
        data._stat = row;
        return data;
      })
      .catch(() => {
        data._stat = null;
        return data;
      });
  }

  dataQuery = (_id) => {
    return this.dataRef.doc(_id).get()
      .then((doc) => {
        if (doc.exists)
          return { '_id': doc.id, ...doc.data() };
        else
          throw new Error(`dataQuery no data - ${_id}`);
      })
      .then((data) => this.processRow(data))
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  keyQuery = (_id) => {
    return this.dataQuery(_id)
      .then((row) => {
        if (row) {
          this._setData([row]);
          return row;
        }
        else {
          this._setData([]);
          throw new Error(null);
          //return null;
        }
      });
  }

  reviewsQuery = (_sid) => {
    if (!this.user)
      return new Promise((resolve) => resolve(null));

    return this.reviewsRef
      .where("_sid", "==", _sid)
      .orderBy('_ts', 'desc')
      .limit(10)
      .get()
      .then((qs) => {
        let posts = [];
        qs.forEach((doc) => {
          posts.push({ _id: doc.id, ...doc.data() });
        });

        return posts;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  insertReview = (_data) => {
    if (!this.user || !this.user.uid)
      return new Promise((resolve, reject) => reject(false));
    
    return this.reviewsRef.add({
        ..._data,
        _uid: this.user.uid,
        _ts: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then((r) => true)
      .catch((err) => new Promise((resolve, reject) => reject(err)));
  }

  deleteReview = (_id) => {
    if (!this.user || !this.user.uid)
      return new Promise((resolve, reject) => reject(false));
    
    return this.reviewsRef.doc(_id).delete()
      .then(() => {
        // console.log(r);
        return true;
      })
      .catch((err) => new Promise((resolve, reject) => reject(err)));
  }

  uploadImage = (file) => {
    const id = this.logsRef.doc().id;
    const filename = id + '.' + file.name.split('.')[1];

    return this.imageRef.child(`images/${filename}`).put(file);
  }

  ///////////////////////////////////////////////////////////////////////////

  setStar = (_data, _force=false) => {
    if (!this.user || !this.user.uid)
      return new Promise((_, reject) => reject(false));

    const addStar = () => {
      const row = {
        id: _data._id,
        name: _data._name,
        starred: true,
        ts: firebase.firestore.FieldValue.serverTimestamp()
      };

      return this.usersRef.doc(this.user.uid).collection('_stars').doc(_data._id)
        .set(row, {merge: true})
        .then(() => this.onUserInfo())
        .then(() => true)
        .catch((err) => {
          console.error('setStar error', err, _data._id);
          return false;
        });
    };

    if (_force)
      return addStar();
    
    return this.usersRef.doc(this.user.uid).collection('_stars').doc(_data._id).get()
      .then((doc) => {
        if (doc.exists)
          return doc.ref.delete()
            .then(() => this.onUserInfo())
            .then(() => false)
            .catch(() => true);
        else
          return addStar();
      })
      .catch(() => {
        return addStar();
      });
  }

  ///////////////////////////////////////////////////////////////////////////

  eventsQuery = (_id) => {
    return this.teetimeRef.collection(_id)
      .where('status', '==', 0)
      // .where('deleted', '==', false)
      .get()
      .then((qs) => {
        let _teetime = [];

        qs.forEach((doc) => {
          _teetime.push({...doc.data(), '_id': doc.id});
        });

        return _teetime;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  insertEvent(_data) {
    if (!this.user || !this.user.uid)
      return;

    const row = {
      uid: this.user.uid,
      name: _data.name,
      date: _data.date,
      time: _data.time,
      size: _data.size,
      contact: _data.contact,
      status: 0,
      ts: firebase.firestore.FieldValue.serverTimestamp()
    };

    return this.teetimeRef.collection(_data._id).add(row)
      .then(() => {
        return this.setStar(_data.info, true)
          .then(() => true)
          .catch(() => true);
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }

  updateEvent(_id, _data) {
    if (!_id || _data.status === undefined)
      return new Promise((_, reject) => reject());

    return this.teetimeRef.collection(_id).doc(_data._id)
      .set({
        status: _data.status,
        uts: firebase.firestore.FieldValue.serverTimestamp()
      }, { merge : true })
      .then((r) => {
        return true;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }

  // reserve = (_id, _data) => {
  //   if (!this.user || !this.user.uid)
  //     return new Promise((resolve, reject) => reject(false));

  //   const row = {
  //     sid: _id,
  //     uid: this.user.uid,
  //     name: _data.name,
  //     date: _data.date,
  //     //time: _data.time,
  //     period: _data.period,
  //     hour: _data.hour,
  //     min: _data.min,
  //     size: _data.size,
  //     contact: _data.contact,
  //     request: _data.request === "1"? _data.requestTxt : _data.request,
  //     ts: firebase.firestore.FieldValue.serverTimestamp()
  //   };

  //   return this.fst.collection("_reserve").add(row)
  //     .then(() => {
  //       return true;
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       return false;
  //     });    
  // }

  getAnalysis = (docId) => {
    return this.analysisRef.doc(docId).get()
      .then((doc) => {
        return doc.data();
      });
  }

  ///////////////////////////////////////////////////////////////////////////

  onAuthStateChanged = () => {
    this.auth.onAuthStateChanged(
      (user) => {
        if (user) {
          this.user = { uid: user.uid, phoneNumber: user.phoneNumber, email: user.email };
          this.onUserInfo();
        }
        else {
          this._setUser(null);
        }
      },
      (err) => {
        console.error('onAuthStateChanged', err);
        this.signOut();
      }
    );
  }

  onUserInfo = () => {
    if (this.usersRefListner)
      this.usersRefListner();
      
    if (!this.user || !('uid' in this.user) || !this.user.uid) {
      this.signOut();
      return;
    }

    this.usersRefListner = this.usersRef.doc(this.user.uid)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          let data = snapshot.data();

          snapshot.ref.collection('_stars').get()
            .then((qs) => {
              const stars = qs.docs;
              data._stars = stars.length > 0? stars.map((star) => ({ id: star.id, ...star.data() })) : [];
              data._stars.sort((f, s) => f.name > s.name? 1: -1);
            })  
            .catch((err) => {
              console.error('usersRefListner', err);
              data._stars = [];
              return [];
            })
            .then((_) => {
              this._setUser({ ...this.user, _info: { _name: data._name, _stars: data._stars } });
            });
        } else {
          this._setUser({ ...this.user, _info: { _name: null, _stars: [] } });
        }
      },
      (err) => {
        console.error(err);
        this.signOut();
      });
  }

  updateUserInfo = (data) => {
    if (!this.user || !this.user.uid)
      return new Promise((resolve, reject) => reject(false));

    return this.usersRef.doc(this.user.uid)
      .set({ ...data }, { merge : true })
      .then(() => true)
      .catch((err) => console.error(err));
  }

  signInWithPhone = (phoneNumber, appVerifier) => {
    return this.auth.signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((authConfirmationResult) => {
        this.authConfirmationResult = authConfirmationResult;
        return true;
      }).catch((error) => {
        console.error(error);
        this.authConfirmationResult = null;
        return false;
      });
  }

  phoneAuthConfirm = (code) => {
    return this.authConfirmationResult.confirm(code)
      .then((result) => {
        this.user = result.user;
        this.onUserInfo();

        return result.user;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  }

  signInWithKakao = () => {
    return this.kakao.signIn()
      .then((token) => {
        return this.auth.signInWithCustomToken(token)
          .then((user) => {
            if (!user.user || !user.user.email || !user.user.emailVerified)
              throw new Error('signInWithCustomToken - invalid user');
            else {
              this.user = user.user;
              this.onUserInfo();
              return true;
            }
          })
          .catch((err) => {
            throw new Error(err);
          });
      }).catch((err) => {
        console.error(err);
        this.kakao.signOut();
        return false;
      });
  }

  // initNaver = () => {
  //   this.naver = new Naver();
  // }

  signOut() {
    if (this.usersRefListner)
        this.usersRefListner();

    return firebase.auth().signOut()
      .then(() => {
        this._setUser(null);
      })
      .catch((err) => {
        console.error('signOut', err);
        this._setUser(null);
      });
  }

  //////////////////////////////////////////////////////
  
  sendKakaoLink = (data) => {
    this.kakao.sendKakaoCustomLink(data);
  }
  
  // addPlusFriend = () => {
  //   this.kakao.addPlusFriend();
  // }

  //////////////////////////////////////////////////////

  // initMessaging = () => {
  //   if (!Notification)
  //     return;

  //   this.messaging.usePublicVapidKey('BJTYVyAsKJ8hbyJrT7HWdNkSv4nIxOUTIQ6MfTMjC6K72sFxHj8kvSkf4x5bfC8814G_hgyqrJNTQ-hCGPrQBvM');

  //   Notification.requestPermission().then((permission) => {
  //     if (permission === 'granted') {
  //       this.messaging.getToken()
  //         .then((currentToken) => {
  //           if (currentToken) {
  //             this.updateUserInfo({ _webToken: currentToken });
  //           }
  //         })
  //         .catch((err) => {
  //           this.updateUserInfo({ _webToken: null });
  //         });
  //     } else {
  //       console.log('Unable to get permission to notify.');
  //     }
  //   });

  //   this.messaging.onTokenRefresh(() => {
  //     this.messaging.getToken().then((refreshedToken) => {
  //       this.updateUserInfo({ _webToken: refreshedToken });
  //     }).catch((err) => {
  //       console.log(err);
  //       this.updateUserInfo({ _webToken: null });
  //     });
  //   });

  //   // Active
  //   this.messaging.onMessage((payload) => {
  //     console.log(payload.notification);
  //   });
  // }

  // getGoogleReviews = (data, callback) => {
  //   return callback([]);
    // const service = new this.google.places.PlacesService(document.createElement('div'));
    // let request = {
    //   query: data._name,
    //   // fields: ['name', 'photos', 'place_id', 'geometry'],
    //   fields: ['name', 'place_id'],
    //   locationBias: { radius: 5, center: { lat: parseFloat(data._location.coordinates[1]), lng : parseFloat(data._location.coordinates[0]) } }
    // };
    
    // service.findPlaceFromQuery(request, (results, status) => {
    //   let reviews = [];

    //   if (status === this.google.places.PlacesServiceStatus.OK && results.length === 1) {
    //     const placeId = results[0].place_id;
        
    //     service.getDetails({ placeId: placeId, fields: ['name', 'reviews', 'photos']}, (place, status) => {
    //       if (status === this.google.places.PlacesServiceStatus.OK && place.reviews) {
    //         place.reviews.forEach((review) => {
    //           if (review.text)
    //             reviews.push({ _id: review.time, _message: review.text, _ts: null, _ratings: review.rating, _uid: 'google'});
    //         });
    //       }
    //       callback(reviews);
    //     });
    //   }
    //   else
    //     callback(reviews);
    // });
  // }
}