import * as React from 'react';
// import ttContext from '../class/Context.jsx';

const PageCC = (props) => {
    const { data } = props;

    return (
        <section className="search-info visual">
            <div className="search-info-header">
                <h2>{data._name}</h2>
            </div>
            <div className="section-info-cc">
                <img src={data._info.thumbnail} alt={data._name}/>
                <ul className="table-info">
                    <li><label>주소</label><span>{data._address.address_name}</span></li>
                    <li><label>전화</label><span>{data._tel}</span></li>
                    <li><label>홀</label><span>{data._info.holes}</span></li>
                </ul>
                <ul className="ul-brands">
                    { data._info.brands.map((b) => <li key={b}><button className={`icon-system icon-${b.toLowerCase()} active`}>{b}</button></li>) }
                </ul>
                <ul className="ul-levels">
                    <li><label>코스 난이도</label><span>5</span></li>
                    <li><label>그린 난이도</label><span>3</span></li>
                </ul>
                <ol className="ul-holes">
                    <li className="header"><label>홀</label><span>파</span></li>
                    { [...Array(18)].map((_, i) =>
                        <li key={i+1}>
                            <label>{i+1}H</label>
                            <span>3</span>
                        </li>
                    )}
                </ol>
            </div>
        </section>
    );
}

export default PageCC;