import React from 'react';
import ReactGA from 'react-ga4';
import { useOutletContext } from "react-router-dom";

import PageCC from './PageCC'
import PageScreen from './PageScreen'

const PageRoute = () => {
    const [ mapPanTo, onKeyClear, data ] = useOutletContext();

    React.useEffect(() => {
        if (data) {
            ReactGA.set({ page: data._id })
            ReactGA.send('pageview')
        }
    }, [data]);

    if (!data || !data._id)
        return null;

    switch (data._ttype) {
        case 'CC':  
            return <PageCC mapPanTo={mapPanTo} onKeyClear={onKeyClear} data={data} />;
        default:
            return <PageScreen mapPanTo={mapPanTo} onKeyClear={onKeyClear} data={data} />;
    }
}

export default PageRoute;