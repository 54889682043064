import React from 'react';
import meta from '../static/meta.json';

export const BadgeType = (props) => {
    const { data } = props;
    let value = '골프존';

    switch (data._ttype) {
        case 'GZ':
            if (data._info.type) {
                if (data._info.type === "gzpark")
                    value = '골프존파크';
                else if (data._info.type === "gdracademy")
                    value = 'GDR아카데미';
            }
            break;
        case 'TU':
            value = '프렌즈스크린';
            if (data._info.type && data._info.type === 'tuprange')
                value = '스크린R';
            break;
        default:
            value = meta.sys[data._ttype]
            break;
        // case 'OD':
        //     value = '실외연습장';
        //     break;
        // case 'XG':
        //     value = 'X-GOLF';
        //     break;
        // case 'SG':
        //     value = 'SG골프';
        //     break;
        // case 'CT':
        //     value = '시티존';
        //     break;
        // case 'QE':
        //     value = 'QED';
        //     break;
        // case 'OK':
        //     value = 'UDR';
        //     break;
        // case 'GT':
        //     value = 'GTS';
        //     break;
        // case 'BV':
        //     value = '브라보';
        //     break;
        // default:
        //     value = data._ttype;
    }
    
    return (
        <span className={`badge ttype ${data._ttype.toLowerCase()}`}>{value}</span>
    )
}

export const BadgeTag = ({ tags }) => {
    if (!tags)
        return null;
    else {
        return (
            <React.Fragment>
                { tags.includes("new") && 
                    <span className="badge new">신규</span>
                }
                { tags.includes("update") && 
                    <span className="badge update">업데이트</span>
                }                                      
                { tags.includes("review") && 
                    <span className="badge review">리뷰</span>
                }
                { tags.includes("valet") && 
                    <span className="badge valet">발렛</span>
                }
                { tags.includes("adlink") && 
                    <span className="badge adlink">할인권</span>
                }
                { tags.includes("event") && 
                    <span className="badge event">이벤트</span>
                }
                { tags.includes("247") && 
                    <span className="badge open">24시간</span>
                }
                { tags.includes("parking") && 
                    <span className="badge parking">주차</span>
                }
                { tags.includes("price") && 
                    <span className="badge price">그린피</span>
                }
                { (tags.includes("lesson") || tags.includes("profiles")) &&
                    <span className="badge lesson">레슨</span>
                }
                { tags.includes("profiles") && 
                    <span className="badge profiles">강사진</span>
                }
                { tags.includes("direct") && 
                    <span className="badge direct">직영점</span>
                }
                { tags.includes("teetime") && 
                    <>
                    <span className="badge letter bolt"><i className="fas fa-bolt"></i></span>
                    <span className="badge bolt-txt">벙개</span>
                    </>
                }
            </React.Fragment>
        )
    }
}

export const BadgeThumbnail = (props) => {
    const { data } = props;
    let value = 'GZ';

    switch (data._ttype) {
        case 'GZ':
            if (data._info.type) {
                if (data._info.type === "gzpark")
                    value = 'GZP';
                else if (data._info.type === "gdracademy")
                    value = 'GDR';
            }
            break;
        case 'SG':
            if (data._info.type && data._info.type === 'sdr')
                value = 'SDR';
            else
                value = 'SG';
            break;
        default:
            value = data._ttype;
            break;
    }

    return (
        <div className={`img no-img ${value}`}></div>
    );
}