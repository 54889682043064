import * as React from 'react';
import { ImageSlider } from './Slider.jsx';
import { GoogleAdArticle } from './GoogleAd';

export const InfoTab = ({ data, meta }) => {
    const _iconCls = 'icon-' + ((data._info.type === "gdracademy")? 'gdr' : data._ttype.toLowerCase());
    const _systems = meta.sys;
    const _prices = data._info.prices && data._info.prices.constructor === Object && Object.keys(data._info.prices).length > 0;

    return (
        <React.Fragment>
        <div className="info-wrap">
            <div className={`div-info icon-system ${_iconCls}`}>
                <label>{_systems[data._ttype]}</label>
            </div>
            { data._info.systems.length > 0 &&
            <div className={`div-info info-device s${data._info.systems? data._info.systems.length : '1'}`}>
                <label>시스템</label>
                <p>{ data._info.systems.map((system) => <span key={system}>{system}</span>) }</p>
            </div>
            }
            <div className="div-info info-parking r1">
                <label>주차</label>
                <p>{data._info.parking === true? '주차가능': '매장문의'}</p>
            </div>
        { data._info.devices > 0 && 
            <div className="div-info info-rooms numeric">
                <label>타석</label>
                <p>{data._info.devices}</p>
            </div>
        }
        { data._info.floor > 0 && 
            <div className="div-info info-floors numeric">
                <label>층수</label>
                <p>{data._info.floor}</p>
            </div>
        }
        { data._info.distance && 
            <div className="div-info info-open numeric">
                <label>비거리</label>
                <p>{data._info.distance}</p>
            </div>
        }
        { data._info.holes && 
            <div className="div-info info-open numeric">
                <label>홀</label>
                <p>{data._info.holes}홀</p>
            </div>
        }
        { data._info.lesson && <div className="div-info info-lesson r1"><label>레슨</label><p>레슨가능</p></div> }
        { data._tags.includes('247') && <div className="div-info info-hours r1"><label>운영시간</label><p>24시간</p></div> }
        {/* data._tags.includes('qving') && <div className="div-info info-qving"><label>큐빙 설치점</label><p>큐빙</p></div> */}
        {/* { data._tags.includes('direct') && <div className="div-info info-direct"><label>직영</label><p>본사 직영점</p></div> } */}
        </div>
        { !data._info['247'] &&
        <div className="div-info2 info-hours">
            <label>운영시간</label>
            <ul>
                <li>
                    <label className="txt">주중</label>
                    { data._info.hours.week.map((hour, i) => <span key={i}>{hour}</span>) }
                    { data._info.hours.week.length === 0 && <span className="empty">정보 미제공</span> }
                </li>
                <li>
                    <label className="txt blue">토요일</label>
                    { data._info.hours.sat.map((hour, i) => <span key={i}>{hour}</span>) }
                    { data._info.hours.sat.length === 0 && <span className="empty">정보 미제공</span> }
                </li>
                <li>
                    <label className="txt red">일요일</label>
                    { data._info.hours.sun.map((hour, i) => <span key={i}>{hour}</span>) }
                    { data._info.hours.sun.length === 0 && <span className="empty">정보 미제공</span> }
                </li>
                { data._info.hours.holiday.length === 2 &&
                <li>
                    <label className="txt red">공휴일</label>
                    { data._info.hours.holiday.map((hour, i) => <span key={i}>{hour}</span>) }
                </li>
                }
            </ul>
        </div>
        }
        {/* <div className="div-info2 info-charges">
            <label>그린피</label>
            { !data._info.charges && <span className="empty">가격은 매장에 문의해주세요.</span> }
            { data._info.charges && data._info.charges.map((charge,i) => <span key={'charge-'+i}>{charge}원</span>) }
        </div> */}
        <div className="div-info2 info-prices">
            <label>그린피</label>
            <ul className="ul-prices">
                { !_prices &&
                    <li className="empty">
                        자세한 요금은 매장에 문의하세요
                    </li>
                }
                {/* { _prices && 
                    <li className="thead">
                        <div></div>
                        <div>시간</div>
                        <div>18홀</div>
                        <div>9홀</div>
                    </li>
                } */}
                { _prices && 
                    Object.keys(data._info.prices).map((i) => {
                        const row = data._info.prices[i];

                        return (
                            <li className="row" key={i}>
                                <div><i>₩</i>{row[6] === 0? '주중' : '주말'}</div>
                                <div>{row[0]} ~ {row[1]}</div>
                                <div className="txt">
                                    <label>18홀</label>
                                    <span>{row[2]}원</span>
                                    { (row[2] !== row[3]) && <span>{row[3]}원</span> }
                                </div>
                                { row[4] &&
                                <div className="txt">
                                    <label>9홀</label>
                                    {/* { !row[4] && <span>-</span> } */}
                                    <span>{row[4]}원</span>
                                    { (row[4] !== row[5]) && <span>{row[5]}원</span> }
                                </div>
                                }
                            </li>
                        );
                    })
                }
            </ul>
        </div>
        { data._info.ad && 
            <div className="div-info2 info-ad info-note">
                <label>할인 정보</label>
                <div className="row">
                    { data._info.ad.desc.split('\n').map((str, i) => <p key={`ad-desc-${i}`}>{str}</p>) }
                    <a href={data._info.ad.link} className="link-ad" target="_blank" rel="noopener noreferrer">이용권 구매하기</a>
                </div>
            </div>
        }
        { data._info.charge && 
            <div className="div-info2 info-note">
                <label>안내</label>
                <div className="row">
                    {data._info.charge.split('\r\n').map((txt, i) => <p key={"note1-"+i}>{txt}</p>)}
                </div>
            </div>
        }
        { data._info.note && 
            <div className="div-info2 info-note">
                <label>공지</label>
                <div className="row">
                    { data._info.note && data._info.note.split('\r\n').map((txt, i) => <p key={"note2-"+i}>{txt}</p>) }
                </div>
            </div>
        }
        { data._info.event && 
            <div className="div-info2 info-note">
                <label>이벤트</label>
                <div className="row">
                    {data._info.event.split('\r\n').map((txt, i) => <p key={"event-"+i}>{txt}</p>)}
                </div>
            </div>
        }
        <div className="div-img info-img">
            <label>매장 사진</label>
            { (!data._info.images || data._info.images.length === 0) && <p className="empty">매장 사진 미제공</p> }
            { (data._info.images && data._info.images.length > 0) && <ImageSlider images={data._info.images} /> }
        </div>
        <div className="div-info2 info-ad">
            <GoogleAdArticle adslot="1810634219" />
        </div>
        <div className="div-row">
            <a href={`https://docs.google.com/forms/d/e/1FAIpQLSd8vtNHtZfzjBGXraHfCui9ZOwQSvtCTxqxXeZDNKwE_X9v-A/viewform?usp=pp_url&entry.2092775499=${data._name}&entry.1498135098=https://teetime.cc/${data._id}&entry.355426373=${data._tel}`} target="_blank" rel="noopener noreferrer"><i className="fas fa-wrench"></i>정보 수정 요청</a>
        </div>
        </React.Fragment>
    );
}

export const TableTab = ({ data, meta }) => {
    const _sys = data._ttype;
    const _data = data._data[_sys];
    const _cols = meta.cols[_sys];

    if (_data) {
        return (
            <table key={'data-' + _sys}>
                <tbody>
                    {Object.keys(_data).map((key, i) => {
                        if (_cols[key] && _data[key] && _data[key] !== '0') {
                            const val = _data[key];

                            return (
                                <tr key={key}>
                                    <td className="label">{_cols[key]}</td>
                                    { typeof val === "string" && val.startsWith('http')?
                                        <td><a href={val} target="_blank" rel="noopener noreferrer">링크 클릭</a></td> :
                                        <td>{val}</td>
                                    }
                                </tr>
                            );
                        }
                        else
                            return null;
                    })}
                </tbody>
            </table>
        );
    } 
    else
        return (
            <div className="message">
                <span className="span-msg">추가 제공 정보가 없습니다.</span>
            </div>
        );
}

export default InfoTab;