import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import './css/App.css';
import { ContextProvider } from './class/Context.jsx';
import Main from './components/Main.jsx';
import PageRoute from './components/PageRoute.jsx';

const App = () => {
  return (
    <ContextProvider>
      <div id="www-app">
          <BrowserRouter>
              <Routes>
                  {/* <Route exact path="/oauthNaver" render={(props) => <Auth {...props} />} /> */}
                  {/* <Route exact path="/stat" render={(props) => <Stat {...props} />} /> */}
                  {/* <Route path="/" render={(props) => <Main {...props} />} /> */}
                  <Route path="/" element={<Main />}>
                    <Route path=":pageId" element={<PageRoute />} />
                  </Route>
                  {/* <Route path="home" element={<Magazine />} /> */}
              </Routes>
          </BrowserRouter>
      </div>
    </ContextProvider>
  );
}

export default App;