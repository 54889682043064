import React, { useContext, useState, useEffect } from 'react';
import ttContext from '../class/Context.jsx'
import { AuthButton } from './Account.jsx';

const EventList = (props) => {
    const { id, info } = props;
    const { user, backend } = useContext(ttContext);
    const [ events, setEvents ] = useState([])
    const [ refresh, setRefresh ] = useState(true);

    useEffect(() => {
        if (user && refresh) {
            backend.eventsQuery(id)
                .then((_events) => {
                    setEvents(_events);
                    setRefresh(false);
                })
                .catch((err) => {
                    console.error(err);
                    setEvents([]);
                    setRefresh(false);
                });
        }
    }, [user, refresh, backend, id]);

    const _updateEvent = (event, v) => {
        event['status'] = v;

        backend.updateEvent(id, event)
            .then(() => setRefresh(true))
            .catch(() => setRefresh(true));
    };

    const _insertEvent = (data) => {
        data.info = info;
        backend.insertEvent(data)
            .then(() => setRefresh(true))
            .catch(() => setRefresh(false));
    };

    if (!user) {
        return (
            <div className="message">
                <span className="span-msg">로그인하시고 스벙을 모집하세요.</span>
                <AuthButton />
            </div>
        );
    }
    else {
        return (
            <section className="section-events">
                <div className="event-list">
                { events && (events.map((row, i) => {
                    row._date = row.date.slice(5, 7) + '월 ' + row.date.slice(8, 10) + '일';
                    
                    let _contact = row.contact;
                    let _contactTxt = '신청하기';
                    const _completed = row.completed? 'completed':'';

                    if (/^\d+$/.test(row.contact) && row.contact.length > 5 && row.contact.length < 12)
                        _contact = "tel:" + row.contact;
                    else if (row.contact && (row.contact).includes('http')) {
                        _contact = row.contact;
                        _contactTxt = '신청 링크';
                    }

                    if (!row.link || row.link.length < 2 || row.link === 'https://')
                        row.link = null;

                    return (
                        <div key={'_teetime_' + i} className={"event-info " + _completed}>
                            <div className="col">
                                <h3>{row._date}</h3>
                                { _completed &&
                                    <div className="event-info-control">
                                        <span className="span-completed">모집 완료</span>
                                    </div>
                                }
                            </div>
                            <ul className="ul-event-info">
                                <li><label>{/*<i className="far fa-clock"></i>*/}티타임</label>{row.time}</li>
                                <li><label>{/*<i className="fas fa-user-friends"></i>*/}인원</label>{row.size}</li>
                                <li><label>{/*<i className="fas fa-running"></i>*/}예약자</label>{row.name.substr(0, 13)}</li>
                                <li>
                                    <label>{/*<i className="fas fa-phone"></i>*/}연락처</label>
                                    {  _contact && <a href={_contact}>{_contactTxt}</a> }
                                    { !_contact && _contact.substr(0, 13) }
                                </li>
                                {/* { row.link && <li><label><i className="fas fa-user-friends"></i>링크</label><a href={row.link} target="__blank">링크</a></li> } */}
                            </ul>
                            { (!_completed && user && user.uid === row.uid) &&
                                <div className="event-info-control">
                                    <button className="btn red" onClick={() => _updateEvent(row, 1)}><i className="fas fa-ban"></i>취소</button>
                                    <button className="btn blue" onClick={() => _updateEvent(row, 2)}><i className="far fa-check-circle"></i>모집 마감</button>
                                </div>
                            }
                        </div>
                    );
                })) }
                { (!events || events.length === 0) &&
                    <span className="span-alert">모집 중인 스벙이 없습니다</span>
                }
                </div>
                <EventForm id={id} insertEvent={_insertEvent} />
            </section>
        );
    }
}


const EventForm = (props) => {
    const { user } = useContext(ttContext)
    const [ date, setDate ] = useState({});
    const [ inputs, setInputs ] = useState({});
    const [ disabled, setDisabled ] = useState(true);
    
    useEffect(() => {
        const curDate = new Date();
        const year = String(curDate.getFullYear());
        const month = String(curDate.getMonth() + 1);
        const day = String(curDate.getDate());
        const days = ['일', '월','화', '수', '목', '금', '토'];
    
        setInputs({
            _id: props.id,
            data: props.data,
            year: year,
            month: month,
            day: day,
            days: 0,
            date: year + month + day,
            time: '19:30',
            size: 4,
            name: (user._info && user._info._name)? user._info._name: user.displayName,
            contact: (user._info && user._info.phoneNumber)? user._info.phoneNumber : '',
            link: 'https://teetime.cc/' + props.id
        });

        setDate({ day: day, month: month, wkday: days[curDate.getDay()] + '요일' });
    }, [props, user]);

    useEffect(() => {
        for (let k in inputs) {
            const v = inputs[k];
            if (v === null || v === '') {
                setDisabled(true);
                return;
            }
        }
        setDisabled(false);
    }, [inputs]);

    const handleChange = (e) => {
        let { name, value } = e.target;
        setInputs((i) => ({ ...i, [name]: value }));
    };

    const handleCheck = (e) => {
        let { value } = e.target;
        const days = parseInt(value);

        let curDate = new Date();
        curDate.setDate(curDate.getDate() + days);

        // const year = String(curDate.getFullYear()).slice(-2);
        const year = String(curDate.getFullYear());
        const month = String(curDate.getMonth() + 1);
        const day = String(curDate.getDate());

        setInputs((_inputs) => ({ ..._inputs, year : year, month : month, day: day, days : days }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const date = inputs.year + '-' + ('0' + inputs.month).slice(-2) + '-' + ('0' + inputs.day).slice(-2);
        const data = { ...inputs, date: date};
        
        // setIsUploading(true);
        setDisabled(true);
        props.insertEvent(data);
    };

    return (
        <div className="event-form">
            <form onSubmit={handleSubmit}>
                <div className="row msg">
                    <span className="span-msg">오늘은 {date.month}월 {date.day}일 {date.wkday} 입니다</span>
                </div>
                <div className="row date">
                    <ul>
                        <li><input type="radio" id="rdDate1" name="rdDate" value={0} onChange={handleCheck} checked={inputs.days === 0} /><label htmlFor="rdDate1">오늘</label></li>    
                        <li><input type="radio" id="rdDate2" name="rdDate" value={1} onChange={handleCheck} checked={inputs.days === 1} /><label htmlFor="rdDate2">내일</label></li>    
                        <li><input type="radio" id="rdDate3" name="rdDate" value={2} onChange={handleCheck} checked={inputs.days === 2} /><label htmlFor="rdDate3">모레</label></li>    
                    </ul>
                </div>  
                <div className="row date">
                    <div className="col">
                    <label className="lbl-short">월</label>
                    <input className="inp-short" type="number" placeholder="월" name="month" min="1" max="12" value={inputs.month? inputs.month : '' } onChange={handleChange} required />
                    </div>
                    <div className="col">
                    <label className="lbl-short">일</label>
                    <input className="inp-short" type="number" placeholder="일" name="day" min="1" max="31" value={inputs.day? inputs.day : ''} onChange={handleChange} required />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                    <label className="lbl-short">시간</label>
                    <input className="inp-short" type="text" placeholder="19:30" name="time" maxLength="10" value={inputs.time? inputs.time : ''} onChange={handleChange} required />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                    <label className="lbl-short">예약자</label>
                    <input className="inp-short" type="text" placeholder="티타임" name="name" value={inputs.name? inputs.name : ''} maxLength="10" onChange={handleChange} required />
                    </div>
                    <div className="col">
                    <label className="lbl-short">모집 인원</label>
                    <input className="inp-short" type="number" placeholder="4명" name="size" value={inputs.size? inputs.size : '' } maxLength="2" onChange={handleChange} required />
                    </div>
                </div>
                <div className="row">
                    <div className="col long">
                    <label>연락처</label>
                    <input type="number" placeholder="휴대폰 번호" name="contact" maxLength="11" value={inputs.contact? inputs.contact : ''} onChange={handleChange} required />
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col long">
                    <label>참고 링크</label>
                    <input type="text" placeholder="클리앙,뽐뿌,골프갤 등" name="link" value={inputs.link? inputs.link : ''} onChange={handleChange} />
                    </div>
                </div> */}
                <div className="row">
                    <button className="btn-event" disabled={disabled}>
                        <span>모집</span>
                        {/* {isUploading? <span className="icon-loading"></span> : <span>모집</span> } */}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default EventList;