import React from 'react';
import Backend from './Backend.jsx'

const ttContext = React.createContext({ backend: null });

export const ContextProvider = ({ children }) => {
    const [ key, resetKey ] = React.useState(+ new Date());
    const [ user, setUser ] = React.useState(null);
    const [ data, setData ] = React.useState(null);
    const [ isLoading, setIsLoading ] = React.useState(true);

    const [ backend ] = React.useState(Backend.getInstance({ setUser : setUser, setData: setData, setIsLoading: setIsLoading, resetKey: resetKey }));

    return (
        <ttContext.Provider value={{ key: key, backend: backend, user: user, data: data, isLoading: isLoading }}>
            { children }
        </ttContext.Provider>
    );
}

export default ttContext;