import * as React from 'react';

const GoogleAd = ({ adslot }) => {
    React.useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return (
        <ins className="adsbygoogle"
            style={{display:'block'}}
            data-ad-format="fluid"
            data-ad-layout-key="-h2-h+4-8i+ms"
            data-ad-client="ca-pub-5041067395646513"
            data-ad-slot={adslot}></ins>
    );
}

export const GoogleAdArticle = ({ adslot }) => {
    React.useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return (
        <ins className="adsbygoogle"
            style={{ 'display':'block', 'textAlign':'center' }}
            data-ad-layout="in-article"
            data-ad-format="fluid"
            data-ad-client="ca-pub-5041067395646513"
            data-ad-slot={adslot}></ins>
    );
}

export default GoogleAd;