import React from 'react';
import { useParams, useNavigate, useLocation, Outlet } from "react-router-dom";
import ReactGA from 'react-ga4';
import ttContext from '../class/Context.jsx'
import Sidebar from './Sidebar.jsx';

const Main = () => {
    const _kakaoMaps = React.useMemo(() => window.kakao.maps, []);
    const { backend, data, isLoading } = React.useContext(ttContext);
    
    const [ _map, setMap ] = React.useState(null);
    const [ _clusterer, setClusterer ] = React.useState(null);

    const [ showSidebar, setShowSidebar ] = React.useState(true);
    const [ dataKey, setDataKey ] = React.useState(null);
    const [ dataIdx, setDataIdx ] = React.useState(null);

    const [ coords, setCoords ] = React.useState([37.5665, 126.9780]);
    const [ location, setLocation ] = React.useState('서울 중구');
    const [ overlays, setOverlays ] = React.useState({});
    const [ marker, setMarker ] = React.useState(null);

    let routeLocation = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    ////////////////////////////////////////////////////////////////////////

    const toggleSidebar = () => setShowSidebar(s => !s);

    const onTagSelect = (tags) => {
        if (!data || data.length === 0)
            return;
        
        backend.filterData(data, tags);
    }

    const onKeyClear = () => {
        Object.keys(overlays).forEach((i) => {
            overlays[i].setZIndex(9);
            let className = overlays[i].getContent().className;
            overlays[i].getContent().className = className.replace(' selected', '');            
        });

        setDataKey(null);
        setDataIdx(null);
    }

    const onKeySelect = (newDataKey) => {
        let newDataIdx = null;

        if (!newDataKey) {
            console.error('onKeySelect', 'newDataKey not found');
            return;
        }
        
        if (dataKey && (newDataKey === dataKey))
            return;

        if (!data)
            return onKeySearch(newDataKey);

        for (let i = 0; i < data.length; i++) {
            if (data[i]._id === newDataKey) {
                newDataIdx = i;
                break;
            }
        }

        if (newDataIdx === null || data[newDataIdx] === undefined) {
            return onKeySearch(newDataKey);
        }

        Object.keys(overlays).forEach((i) => {
            overlays[i].setZIndex(9);
            let className = overlays[i].getContent().className;
            overlays[i].getContent().className = className.replace(' selected', '');            
        });
        
        setDataKey(newDataKey);
        setDataIdx(newDataIdx);
        
        navigate(newDataKey);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    const setBounds = () => {
        if (!overlays || Object.keys(overlays).length === 0)
            return;
        
        let bounds = new _kakaoMaps.LatLngBounds();

        Object.keys(overlays).forEach((k) => {
            bounds.extend(overlays[k].getPosition());
        });

        _map.setBounds(bounds);
        setOverlays(overlays);
    }

    const displayMarker = (kcoords) => {
        let content = document.createElement('div');
        content.className = 'overlay-marker';

        if (marker)
            marker.setPosition(kcoords);
        else
            setMarker(new _kakaoMaps.CustomOverlay({
                map: _map,
                position: kcoords,
                content: content,
                zIndex: 10
            }));
    }

    const displayPlace = (data, idx) => {
        if (!data || data._id === undefined || data._ttype === undefined || data.hidden === true)
            return;
        
        let zIndex = 9;
        const key = data._id;
        const ttype = data._ttype.toLowerCase();
        const _coords = new _kakaoMaps.LatLng(data._geo.geopoint.latitude, data._geo.geopoint.longitude);
        
        let content = document.createElement('div');
        content.className = `overlay-info ${ttype}`;
        content.innerHTML = `<span class="title ${ttype}">${data._name}</span>`; 
        content.setAttribute('dataKey', key);
        content.setAttribute('dataIdx', idx);

        if (data._tags && data._tags.includes("teetime")) {
            zIndex = 999;
            content.innerHTML += '<span class="badge bolt"><i class="fas fa-bolt"></i></span>';
        }
        
        let overlay = new _kakaoMaps.CustomOverlay({
            map: _map,
            position: _coords,
            content: content,
            zIndex: zIndex,
            clickable: true,
            yAnchor: 1,
        });
        overlays[key] = overlay;

        content.addEventListener('click', (_) =>  onKeySelect(key));
        content.addEventListener('mouseover', (_) => overlays[key].setZIndex(99));
        content.addEventListener('mouseout', (_) => overlays[key].setZIndex(9));

        _clusterer.addMarker(overlay);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    const keywordSearch = (keyword) => {
        if (!keyword) return;

        new _kakaoMaps.services.Geocoder().addressSearch(keyword,
            (data, status) => {
                if (status === _kakaoMaps.services.Status.OK && data && data[0]) { // Address found
                    const _coords = [parseFloat(data[0].y), parseFloat(data[0].x)];
                    geoQuery(_coords);
                }
                else
                    backend.keywordSearch(keyword, geoQuery); // Keyword search
            }
        );

        ReactGA.event({
            category: 'Search',
            action: 'keywordSearch',
            label: keyword
        });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    const clearMap = () => {
        if (_clusterer)
            _clusterer.clear();
        
        Object.keys(overlays).forEach((key) => {
            overlays[key].setMap(null);
            delete overlays[key];
        });
        
        setOverlays({});
        setDataKey(null);
    }
    
    // const mapZoom = (level) => _map.setLevel(_map.getLevel() + level);

    const mapPanTo = (key) => {
        setShowSidebar(false);

        _map.relayout();
        _map.setLevel(3);
        _map.panTo(overlays[key].getPosition());
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    const geoQuery = (_coords) => {
        setCoords(_coords);
        backend.geoQuery({ center: _coords, radius: 2 });
    }

    const getGeoRegion = () => {
        const geocoder = new _kakaoMaps.services.Geocoder();
        geocoder.coord2Address(coords[1], coords[0],
            (data) => {
                if (data && data[0] && data[0].address) {
                    const addr = data[0].address;
                    setLocation(addr.region_1depth_name + ' ' + addr.region_2depth_name);
                }
            });
    }

    const getGeoPosition = () => {
        backend.getCurrentPosition()
            .then((_coords) => geoQuery(_coords))
            .catch(() => geoQuery(coords));
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    const refreshMap = () => {
        if (!_map) return;

        let center = _map.getCenter();
        const _coords = [center.getLat(), center.getLng()];
        
        geoQuery(_coords);

        ReactGA.event({
            category: 'Click',
            action: 'refreshMap'
        });
    }

    const moveToCurrentPosition = () => {
        getGeoPosition();

        ReactGA.event({
            category: 'Click',
            action: 'moveToCurrentPosition'
        });
    }

    const onKeySearch = (_id) => {
        backend.keyQuery(_id)
            .then(() => {
                setDataKey(_id);
                setDataIdx(0);
            });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    
    React.useEffect(() => {
        if (!params.pageId)
            getGeoPosition();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {        
        if (params.pageId) {
            return onKeySelect(params.pageId);
        } 
        
        if (routeLocation.search) {
            const splits = routeLocation.search.split('=');
            if (decodeURI(splits[0]) === '?search' && splits[1].length > 1)
                return keywordSearch(decodeURI(splits[1]));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.pageId]);

    React.useEffect(() => {
        if (!_kakaoMaps) return;
        
        const locPosition = new _kakaoMaps.LatLng(coords[0], coords[1]);
        setMap(new _kakaoMaps.Map(document.getElementById("map"), { center: locPosition, level: 5 }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_kakaoMaps]);

    // 
    React.useEffect(() => {
        if (!_map) return;

        _map.setCopyrightPosition(_kakaoMaps.CopyrightPosition.BOTTOMRIGHT, true);

        if (!_clusterer) {
            setClusterer(new _kakaoMaps.MarkerClusterer({
                map: _map,
                averageCenter: true,
                disableClickZoom: false,
                minLevel: 7,
                removeMarker: 1,
                styles: [{
                    width : '53px',
                    height : '53px',
                    background: '#BED0DC',
                    color: '#444',
                    textAlign: 'center',
                    lineHeight: '53px',
                    borderRadius: '100%',
                    fontWeight: '900',
                    borderColor: '#444'
                }]
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_map]);

    React.useEffect(() => {
        if (!_map || !coords) return;

        let locPosition = new _kakaoMaps.LatLng(coords[0], coords[1]);
        _map.setCenter(locPosition);

        getGeoRegion();
        displayMarker(locPosition);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coords]);

    React.useEffect(() => {
        if (!_map || !data) return;

        clearMap();

        data.forEach((row, i) => displayPlace(row, i));
        setBounds();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (dataKey && overlays[dataKey]) { 
            overlays[dataKey].getContent().className += ' selected';
            overlays[dataKey].setZIndex(9999);
            
            _map.setLevel(3);
            _map.panTo(overlays[dataKey].getPosition());
        }
    }, [_map, dataKey, overlays]);

    //////////////////////////////////////////////////////////////////////////////////////////

    return (
        <React.Fragment>
            <div id="www-contents">
                { isLoading &&
                    <div className="loading-overlay">
                        <div className="loading-wrap"><div className="icon-loading"></div></div>
                    </div>
                }
                <div className="contents-inner">
                    <Sidebar 
                        refreshMap={refreshMap}
                        toggleSidebar={toggleSidebar}
                        keywordSearch={keywordSearch}
                        onTagSelect={onTagSelect}
                        onKeySelect={onKeySelect}
                        location={location}
                        showSidebar={showSidebar}
                    />

                    <Outlet 
                        context={[mapPanTo, onKeyClear, data? data[dataIdx] : null]} 
                    />
                    
                    <section className="section-map" id="map"></section>
                    <div className="map-control">
                        <ul className="loc-control">
                            <li><button className="btn" onClick={refreshMap}><i className="fas fa-redo-alt"></i><span>다시 검색</span></button></li>
                            <li><button className="btn" onClick={moveToCurrentPosition}><i className="fas fa-map-marker-alt"></i><span>현재 위치로</span></button></li>
                            {/* <li><NavLink className="btn" to="/home"><i className="fas fa-home"></i></NavLink></li> */}
                        </ul>
                        {/* <ul className="zoom-control">
                            <li><button className="btn in" onClick={() => mapZoom(-1)} title="지도 확대"><i className="fas fa-plus"></i></button></li>
                            <li><button className="btn out" onClick={() => mapZoom(1)} title="지도 축소"><i className="fas fa-minus"></i></button></li>
                        </ul> */}
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}

const Footer = () => {     
    return (    
        <footer id="footer">
            <div className="footer-modal">
                {/* <span className="data">데이터 출처: 골프존,카카오VX,SGM,XPGA,시티존,GTS,UDR,YGQED</span> */}
            </div>
        </footer>
    );
};

export default Main;