import * as React from 'react';
import { Link } from "react-router-dom";
import { BadgeType, BadgeTag, BadgeThumbnail } from './Badges';
import ttContext from '../class/Context';
// import GoogleAd from './GoogleAd';

const SearchList = (props) => {
    const { data } = React.useContext(ttContext);

    let noResult = true;

    return (
        <div className="search-list">
            <ul>
                { data &&
                    data.map((row, i) => {
                        const id = row._id;
                        const hasThumbnail = row._info.thumbnail || (row._info.images && row._info.images.length > 0)? 'thumbnail' : false;
                        const urlThumbnail = row._info.thumbnail? row._info.thumbnail : `https://s3.ap-northeast-2.amazonaws.com/img.teetime.cc/thumbnails/${id.toLowerCase()}.png`;

                        if (row.hidden)
                            return null;
                        else {  
                            noResult = false;

                            return (
                                <React.Fragment key={id}>
                                {/* { props.isActive && i > 0 && Math.floor(i % 5) === 0 && <li key={`in-list-ad-${i}`} className="ad-google"><GoogleAd adslot="4813721014" /></li> } */}
                                <li key={id}>
                                    <Link to={id} className={`btn ${hasThumbnail}`}>
                                        { hasThumbnail &&
                                            <div className="img">
                                                <img 
                                                className="img-thumbnail"
                                                src={urlThumbnail} 
                                                alt={row._name}
                                                onError={(e) => {e.target.src="/icon-tt.png"; e.target.onerror = null;}} 
                                                />
                                            </div>
                                        }
                                        { !hasThumbnail &&
                                            <BadgeThumbnail data={row} />
                                        }
                                        <div className="col">
                                            <div className="title">
                                                <span className="name">{row._name}</span>
                                                <BadgeType data={row} />
                                            </div>
                                            <span className="desc">
                                                { row._address? row._address.address_name.substr(0,15) : '' }
                                            </span>
                                            <div className="badges">
                                                <BadgeTag tags={row._tags} />
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                </ React.Fragment>
                            );
                    }})
                }
                { (data && noResult) &&
                    <li className="empty">검색 결과가 없습니다</li>
                }
                <li className="btn">
                    <button className="btn-refresh" onClick={props.refreshMap} title="현재 위치에서 다시 검색">
                        <i className="fas fa-redo-alt"></i><span>현재 지도 위치 다시 검색</span>
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default SearchList;